@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Chivo+Mono:ital,wght@0,500;1,500&family=Damion&family=Fragment+Mono:ital@0;1&family=Nunito:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.App {
  text-align: center;
}
body{
  background-color: rgb(140, 209, 243);
}
h1{
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  color: #fc6;
  text-shadow: 2px 2px 3px #666;
  font-size: 2.8rem;
}
img{
  box-shadow: 2px 2px 6px #666;
}
h2{
  font-size: 1.8rem;
}
form{
  margin: 24px auto;
}
input, input:focus{
  font-family: 'Nunito', sans-serif;
  font-size: 1.2rem;
  border: 0;
  margin: 12px 12px;
  padding: 6px 12px;
  outline: none;
}
input[type=text]{
  border-radius: 6px 0 0 6px;
  margin-right: 0;
}
input[type=submit]{
  border-radius: 0 6px 6px 0;
  margin-left: 0;
}
input[type=submit]:hover{
  border-radius: 0 6px 6px 0;
  margin-left: 0;
  color: #fff;
  background-color: #fc6;
}
P{
  font-family: 'Nunito', sans-serif;
  font-style: italic;
  font-weight: 700;
  font-size: 1.2rem;
}